import { combineReducers, configureStore } from '@reduxjs/toolkit'
import loginReducer from './authRedux';
import sliderReducer from './sliderRedux';
import loadingReducer from './loadingRedux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk'


const reducers = combineReducers({
    loading:loadingReducer,
    login: loginReducer,
    slider: sliderReducer,
});

const persistConfig = {
    key: 'root',
    storage: storage,
    // blacklist: ['navigation']
  };
  
  const persistedReducer = persistReducer(persistConfig, reducers);
  
  export const store = configureStore({
    reducer: persistedReducer,
    devTools : process.env.NODE_ENV !== 'production',
    middleware: [thunk],
  })