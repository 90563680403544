import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import loaderGif from '../../assets/gifs/loader.gif';
import '../../scss/loaderStyle.scss';

export const LoaderScreen = () =>{
    const {isLoading} = useSelector((state)=>state.loading);
    return (
        <section style={isLoading ? {display:'flex'} : {display:'none'} }>
            <div className="loaderDiv">
                <img className="loaderImage" src={loaderGif} /> 
            </div>
        </section>
    )
}