import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    sidebarShow: true,
    unfoldable: true,
}

export const sliderSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setChangeSliderState: (state, action) => {
            state.sidebarShow = action.payload
        },
        setChangeUnfoldableState: (state, action) => {
            state.unfoldable = action.payload
        },
    },
})

export const { setChangeSliderState, setChangeUnfoldableState } = sliderSlice.actions

export default sliderSlice.reducer