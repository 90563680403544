import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  setUserLogin,
  setUserRoutes,
  setUserToken,
  setUserType,
} from "./authRedux";

export const ClearStorage = () => {
  const dispatch = useDispatch();
  const inactivityTimeoutRef = useRef(null);
  const activityEvents = ["click"]; //["mousemove", "keydown", "click"]

  const clearSession = () => {
    dispatch(setUserToken(""));
    dispatch(setUserType(""));
    dispatch(setUserRoutes(""));
    dispatch(setUserLogin(false));
  };

  // Session Will Clear if user is inactive since last 30 minutes.
  useEffect(() => {
    const resetInactivityTimeout = () => {
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      inactivityTimeoutRef.current = setTimeout(clearSession, 30 * 60 * 1000); // 30 minutes
    };
    activityEvents.forEach((event) => {
      window.addEventListener(event, resetInactivityTimeout);
    });
    resetInactivityTimeout();
    return () => {
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetInactivityTimeout);
      });
    };
  }, [dispatch]);

  // Session Will Clear if user Refresh or Close any tab.
  useEffect(() => {
    window.addEventListener("unload", clearSession);
    return () => {
      window.removeEventListener("unload", clearSession);
    };
  }, []);
}; //  Close ClearStorage
