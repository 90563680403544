import React, { Component, Suspense } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import AuthNavigation from "./components//navigation/authNavigate";
import { ReactNotifications } from "react-notifications-component";
import { LoaderScreen } from "./components/loader/loader";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <LoaderScreen />
          <ReactNotifications />
          <AuthNavigation />
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
