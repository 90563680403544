import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isUserLogin: false,
  userToken : '',
  userType : '',
  userRoutes : '',
  userCompanyName : '',
  userCompanyLogo : '',
  userFooterContent:''
}

export const loginSlice = createSlice({
  name: 'isUserLogin',
  initialState,
  reducers: {
    setUserLogin: (state, action) => {
      state.isUserLogin = action.payload
    },
    setUserToken: (state, action) => {
      state.userToken =action.payload
    },
    setUserType: (state, action) => {
      state.userType =action.payload
    },
    setUserRoutes: (state, action) => {
      state.userRoutes =action.payload
    },
    setUserCompanyName: (state, action) => {
      state.userCompanyName =action.payload
    },
    setUserCompanyLogo: (state, action) => {
      state.userCompanyLogo =action.payload
    },
    setUserFooterContent: (state, action) => {
      state.userFooterContent =action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUserLogin, setUserToken, setUserType, setUserRoutes,setUserCompanyName,setUserCompanyLogo,setUserFooterContent } = loginSlice.actions

export default loginSlice.reducer